<template>
    <div class="col-right">
        <a-card>
            <h2 class="survey ng-star-inserted"> 实时概览 <span>{{time}}</span></h2>
        </a-card>
        <a-card>
            <h3>核心数据</h3>
            <a-row :gutter="40">
                <a-col span="8">
                    <a-card class="stat" :bodyStyle="{padding: '20px 24px 8px'}">
                        <a-space direction="vertical" style="width: 100%;">
                            <div class="g2-card__top">
                                <div class="g2-card__avatar">
                                    <img src="../../assets/img/situation_icon01.png" style="width: 56px;"
                                         class="ng-star-inserted">
                                </div>
                                <div
                                        class="g2-card__meta-wrap">
                                    <div class="g2-card__meta"><span
                                            class="g2-card__meta-title ng-star-inserted">采购金额（元）</span><span
                                            class="g2-card__meta-action ng-star-inserted">
                              <a-icon type="question-circle"/>
                          </span></div>
                                    <p
                                            class="g2-card__total ng-star-inserted">¥{{purchaseData.purchaseOrderAmount}}</p></div>
                            </div>
                            <div class="g2-card__desc"></div>
                        </a-space>
                    </a-card>
                </a-col>
                <a-col span="8">
                    <a-card class="stat" :bodyStyle="{padding: '20px 24px 8px'}">
                        <a-space direction="vertical" style="width: 100%;">
                            <div class="g2-card__top">
                                <div class="g2-card__avatar">
                                    <img src="../../assets/img/situation_icon02.png" style="width: 56px;"
                                         class="ng-star-inserted">

                                </div>
                                <div
                                        class="g2-card__meta-wrap">
                                    <div class="g2-card__meta"><span
                                            class="g2-card__meta-title ng-star-inserted">采购订单数</span><span
                                            class="g2-card__meta-action ng-star-inserted">
                              <a-icon type="question-circle"/>
                          </span></div>
                                    <p
                                            class="g2-card__total ng-star-inserted">{{purchaseData.purchaseOrderNum}}</p></div>
                            </div>
                            <div class="g2-card__desc"></div>
                        </a-space>
                    </a-card>
                </a-col>
                <a-col span="8">
                    <a-card class="stat" :bodyStyle="{padding: '20px 24px 8px'}">
                        <a-space direction="vertical" style="width: 100%;">
                            <div class="g2-card__top">
                                <div class="g2-card__avatar">
                                    <img src="../../assets/img/situation_icon03.png" style="width: 56px;"
                                         class="ng-star-inserted">

                                </div>
                                <div
                                        class="g2-card__meta-wrap">
                                    <div class="g2-card__meta"><span
                                            class="g2-card__meta-title ng-star-inserted">采购商品数</span><span
                                            class="g2-card__meta-action ng-star-inserted">
                              <a-icon type="question-circle"/>
                          </span></div>
                                    <p
                                            class="g2-card__total ng-star-inserted">{{purchaseData.purchaseGoodsNum}}</p></div>
                            </div>
                            <div class="g2-card__desc"></div>
                        </a-space>
                    </a-card>
                </a-col>
            </a-row>
            <echart echartId="purchase-chart" :options="options"></echart>
        </a-card>
<!--        <a-card>-->
<!--            <a-row class="header">-->
<!--                <a-col span="8" class="flex">-->
<!--                    <h3>商品采购单-->
<!--                        <a-icon type="question-circle" style="margin-left: 10px;"/>-->
<!--                    </h3>-->

<!--                </a-col>-->
<!--            </a-row>-->
<!--            <a-table :columns="buyerGoodsColumns" :data-source="buyerGoodsList" :scroll="{x:true}">-->
<!--                <div slot="sort" slot-scope="text, record, index">{{index+1}}</div>-->
<!--            </a-table>-->
<!--        </a-card>-->
    </div>
</template>

<script>
    import echart from '../../component/echart';
    import service from "../../../utils/request";

    export default {
        name: "situation",
        components: {
            echart
        },
        data() {
            return {
                options:{
                    tooltip:{
                        trigger: 'axis'
                    },
                    legend:{
                        data:['时段采购金额', '累计采购金额'],
                    },
                    xAxis: {
                        type: 'category',
                        data: []
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: "时段采购金额",
                            type: "bar",
                            data: [],
                            lineStyle:{
                                normal:{
                                    color: '#3474FD'
                                }
                            },
                            itemStyle:{
                                normal:{
                                    color: '#3474FD'
                                }
                            }
                        },
                        {
                            name: "累计采购金额",
                            type: "line",
                            data: [],
                            lineStyle:{
                                normal:{
                                    color: '#8CDED8'
                                }
                            },
                            itemStyle:{
                                normal:{
                                    color: '#8CDED8'
                                }
                            }
                        },
                    ]
                },
                buyerGoodsColumns: [
                    {title: '排名', dataIndex: 'sort', key: 'sort', scopedSlots: {customRender: 'sort'},},
                    {title: '商品名称', dataIndex: 'goods_title', key: 'goods_title'},
                    {title: '商品编号', dataIndex: 'goods_code', key: 'goods_code'},
                    {
                        title: '采购订单数',
                        dataIndex: 'order_num',
                        key: 'order_num',
                        defaultSortOrder: 'descend',
                        sorter: (a, b) => a.order_num - b.order_num,
                    },
                    {
                        title: '采购件数',
                        dataIndex: 'order_goods_num',
                        key: 'order_goods_num',
                        defaultSortOrder: 'descend',
                        sorter: (a, b) => a.order_goods_num - b.order_goods_num,
                    },
                    {
                        title: '采购金额(元)',
                        dataIndex: 'price',
                        key: 'price',
                        defaultSortOrder: 'descend',
                        sorter: (a, b) => a.price - b.price,
                    },
                ],
                buyerGoodsList: [
                    {
                        goods_title: '御泥坊清爽平衡爽肤水150ml女补水保湿控油化妆水护肤品正品滋润健康水 清透控油 补水保湿 水油平衡',
                        goods_code: '1403190599059816449',
                        order_num: 3,
                        order_goods_num: 6,
                        price: 120.88
                    }
                ],
                purchaseData:{},
                xAxis:[],
                time:'',
            }
        },
        created() {
            for (let i=0; i< 24; i++){
                let time = i > 9 ? i : '0' + i;
                this.xAxis.push(time);
            }
            this.getPurchaseDataHour();
            this.listPurchaseDataHour();
            let date = new Date();
            console.log(date)
            let today = date.getFullYear() + '-'+ date.getMonth()+'-'+date.getDate();
            this.time = today +' 00:00:00';
            this.time += ' ~ '+today+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds();
        },

        methods: {
            getPurchaseDataHour(){
                service.post(service.uri.order.getPurchaseDataHour).then(res => {
                    if(res.code == 200){
                        this.purchaseData = res.data
                    }
                });
            },
            listPurchaseDataHour(){
                service.post(service.uri.order.listPurchaseDataHour).then(res => {
                    if(res.code == 200){

                        this.options = {
                            tooltip:{
                                trigger: 'axis'
                            },
                            legend:{
                                data:['时段采购金额', '累计采购金额'],
                            },
                            xAxis: {
                                type: 'category',
                                data: this.xAxis
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: [
                                {
                                    name: "时段采购金额",
                                    type: "bar",
                                    data: res.data.amount,
                                    lineStyle:{
                                        normal:{
                                            color: '#3474FD'
                                        }
                                    },
                                    itemStyle:{
                                        normal:{
                                            color: '#3474FD'
                                        }
                                    }
                                },
                                {
                                    name: "累计采购金额",
                                    type: "line",
                                    data: res.data.total,
                                    lineStyle:{
                                        normal:{
                                            color: '#8CDED8'
                                        }
                                    },
                                    itemStyle:{
                                        normal:{
                                            color: '#8CDED8'
                                        }
                                    }
                                },
                            ]
                        }
                    }
                });
            },

        }
    }
</script>

<style lang="less" scoped>
    .survey {
        margin-bottom: 0;
        color: #333;
        font-size: 18px;
    }

    .flex {
        display: flex;
        align-items: center;
    }

    .header {
        margin-bottom: 32px;

        h3 {
            margin-right: 10px;
        }
    }

    .g2-card__avatar {
        position: relative;
        top: 4px;
        float: left;
        margin-right: 20px;
    }

    .ant-spin-container {
        position: relative;
        transition: opacity .3s;

    }

    .g2-card__top {
        width: 100%;
        overflow: hidden;
    }

    .g2-card__meta {
        height: 22px;
        color: rgba(0, 0, 0, .45);
        font-size: 14px;
        line-height: 22px;

        .g2-card__meta-action {
            position: absolute;
            top: 4px;
            right: 0;
            line-height: 1;
            cursor: pointer;
        }

        .anticon {
            display: inline-block;
            color: inherit;
            font-style: normal;
            line-height: 0;
            text-align: center;
            text-transform: none;
            vertical-align: -0.125em;
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    .g2-card, .g2-card__top {
        position: relative;
    }

    .g2-card__total {
        height: 38px;
        margin-top: 4px;
        margin-bottom: 0;
        overflow: hidden;
        color: rgba(0, 0, 0, .85);
        font-size: 30px;
        line-height: 38px;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
    }

    .g2-card__desc {
        width: 100%;
        margin-bottom: 12px;
    }
</style>